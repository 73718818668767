import { Container } from "@mui/material";
import React, {useState, useEffect} from "react";
import Header from './headerPage';
import Footer from './footerpage';
import { primary } from "../utils/colors";
import _ from "lodash";
import axios from "axios";
import styled from "@emotion/styled";

function LayoutPage(props: any) {
  const { children } = props;
  const [company, setCompany] = useState();
  const [productCategorys, setProductCategorys] = useState<any[]>([])



  useEffect(() => {
      if (_.isEmpty(company)) {
        axios.get(
            `${process.env.REACT_APP_API_DOMAIN}/company`,
            {headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }}
        ).then(res => { 
          setCompany(res.data);
        })
        .catch((error) => {
            console.log('error', error)
        })

      }
      if (_.isEmpty(productCategorys)) {
        axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/productCat/`,
          {headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }}
          
        ).then(res => {
          setProductCategorys(res.data);
        })
        .catch((error) => {
            console.log('error', error)
        })
      }
  }, [])
  const StyledDiv = styled.div`
    position: sticky;
    box-shadow: rgba(33, 33, 33, 0.16) 0px 1px 14px 0px, rgba(0, 0, 0, 0.27) 0px 0px 4px 0px;
    bottom: 1%;
    left: 99%;
    z-index: 1000;
    border: 1px solid #ccc;
    border-radius: 36px;
    background: #fff;
    width: 68px;
    height: 68px;
    text-align: center;
    transition: all 0.3s;
    -webkit-animation: move-ani 3s linear forwards;
            animation: move-ani 3s linear forwards;
                align-content: center;
                cursor: pointer;
`;

return (
  <React.Fragment>
    <Header company={company} productCategorys={productCategorys}/>
    <Container maxWidth={'xl'} sx={{minHeight: '65vh', mb: 4}}>
      {children}
    </Container>
    <Container maxWidth={false} sx={{backgroundColor: primary, color: 'white'}} disableGutters>
      <Footer company={company} />
    </Container>
    <StyledDiv
                onClick={() => window.open('https\://m.me/100084799852565')}
                >
      <div id='fb-messenger-logo'>
        <svg xmlns="http://www.w3.org/2000/svg" color="#335cff" width="32" height="32" fill="currentColor" className="bi bi-messenger" viewBox="0 0 16 16">
          <path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.64.64 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.64.64 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76m5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"/>
        </svg>
      </div>
    </StyledDiv>
  </React.Fragment>
);
}
export default LayoutPage;
import {
    Box,
    Paper,
    Grid,
    Tabs,
    Tab,
    Typography,
    CardMedia,
    Divider,
    Container,
    Hidden
} from "@mui/material"
import LayoutPage from '../../components/layoutPage';
import Carousel from 'react-material-ui-carousel';
import ProductItem from '../../components/productItem';
import { useTranslation } from 'react-i18next';
import React, {useEffect, useState} from 'react';
import axios from "axios";
import ProductSlideshow from "./ProductSlideshow";
import ProductCategoryMenuItem from "../../components/productCategoryMenuItem";
import NewsRelated from "../news/NewsRelated";

function HomePage() {
    const { t } = useTranslation();

    const items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            description: "Hello World!"
        }
    ]

    function Item(props: any)
    {
        return (
            <Paper>
                <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                      'background-size': 'contain'
                    }}
                    image="https://funico.com.vn/static/media/Funico_logo_top.51fb6635f9cde184541d.png"
                />
                {/* <Button className="CheckButton">
                    Check it out!
                </Button> */}
            </Paper>
        )
    }
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 1 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    const [productCategorys, setProductCategorys] = useState<any[]>([])
    const [productsOnSale, setProductsOnSale] = useState<any[]>([])
    const [productsOnBestSeller, setProductsOnBestSeller] = useState<any[]>([])
    const [productsNewProduct, setProductsNewProduct] = useState<any[]>([])

    useEffect(() => {
        const getData = (doamin: any ) => {
            axios.get(`${doamin}/products/`, {
                    params: { on_sale: true, on_best_seller: true, is_new_product: true },
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                      }
                    }
                )
                .then(res => {
                    setProductsOnSale(res.data.filter((product: any) => product.on_sale === true).slice(0, 12))
                    setProductsOnBestSeller(res.data.filter((product: any) => product.on_best_seller === true).slice(0, 12))
                    setProductsNewProduct(res.data.filter((product: any) => product.is_new_product === true).slice(0, 12))
                })
            
            axios.get(
                `${doamin}/productCat/`, 
                {headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                  }})
                .then(res => {
                    setProductCategorys(res.data)
                })
        }

        getData(process.env.REACT_APP_API_DOMAIN)
    }, [])

    return (
        <LayoutPage>
            <Container maxWidth={'xl'} disableGutters>
                <Box>
                    <Grid container spacing={1}>
                        <Hidden mdDown>
                            <Grid item md={3} xs={12}>
                                <Paper sx={{height: '100%'}}>
                                    <ProductCategoryMenuItem productCategorys={productCategorys}/>
                                </Paper>
                            </Grid>
                        </Hidden>
                        <Grid item md={9} xs={12} sx={{borderBottom: '1px solid #aeaeae'}}>
                            <Carousel>
                                {
                                    items.map( (item, i) => <Item key={i} item={item} /> )
                                }
                            </Carousel>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '100%', mb: 4 }}>
                    <Tabs centered value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={<Typography sx={{fontWeight: 500}}>{t('new_product')}</Typography>} {...a11yProps(0)}/>
                        <Tab label={<Typography sx={{fontWeight: 500}}>{t('best_seller')}</Typography>} {...a11yProps(1)} />
                        <Tab label={<Typography sx={{fontWeight: 500}}>{t('on_sale')}</Typography>} {...a11yProps(2)} />
                    </Tabs>
                    <Divider />
                    {[productsOnSale, productsOnBestSeller, productsNewProduct].map((items, index) => (
                        <TabPanel value={value} index={index} p={0}>
                            <Grid container spacing={1}>
                                {items && items.map(product => (
                                    <Grid item md={2} sm={3} xs={6}>
                                        <ProductItem props={{'product': product}} />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                    ))}
                </Box>
                {productCategorys && productCategorys.map(productCat =>
                    <ProductSlideshow productCat={productCat}/>
                )}
                <NewsRelated />
            </Container>
        </LayoutPage>
    );
}

export default HomePage;
import * as React from 'react';
import {
    IconButton,
    InputBase,
    Paper,
    Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import ProductCategoryMenuItem from '../productCategoryMenuItem';
import axios from "axios";


export default withTranslation() (function SidebarsProduct(props: any) {
  const { t } = useTranslation();
  const [productCategorys, setProductCategorys] = React.useState<any[]>([])

  React.useEffect(() => {
    const getData = async(domain: any) => {
      axios.get(
        `${domain}/productCat/`, 
        {headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }})
        .then(res => {
            setProductCategorys(res.data)
        })
    }
    getData(process.env.REACT_APP_API_DOMAIN);

  }, [])
  const navigate = useNavigate();

  const slug = (str: any) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
  
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
             .replace(/\s+/g, '-') // collapse whitespace and replace by -
             .replace(/-+/g, '-'); // collapse dashes
  
    return str;
  };

  const seachProducts = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const searchInput = data.get('search_input')
        if (searchInput)
            navigate(`/category_product?product_name=${slug(searchInput)}`)
    }

  return (
    <React.Fragment>
        <Paper
            component="form"
            onSubmit={seachProducts}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('search_product')}
                inputProps={{ 'aria-label': 'Tim kiem' }}
                name="search_input"
            />
            <IconButton sx={{ p: '10px' }} aria-label="search" type="submit">
                <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
        <Paper>
            <ProductCategoryMenuItem productCategorys={productCategorys}/>
        </Paper>
    </React.Fragment>
  );
})

import { 
    AppBar,
    Typography,
    Box,
    Container,
    Button,
    Grid,
    Toolbar,
    ButtonGroup,
    Popper,
    Grow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    ClickAwayListener,
    Avatar,
    Hidden,
    List,
    ListItem,
    ListItemButton,
    Divider,
    ListItemText,
    SwipeableDrawer,
} from "@mui/material";
import InventoryIcon from '@mui/icons-material/Inventory';
import ArticleIcon from '@mui/icons-material/Article';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import SearchIcon from '@mui/icons-material/Search';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import React, { useEffect } from "react";
import InputBase from '@mui/material/InputBase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { logout, isAuthenticated } from "../utils/common";
import CartHeader from "./cartHeader";
import { primary } from "../utils/colors";
import flagAmerica from '../static/Funico_flag_America.jpg';
import flagVietNam from '../static/Funico_flag_VietNam.jpg';
import flagGermany from '../static/Funico_flag_Germany.jpg';
import FunicoLogo from '../static/Funico_logo_top.png';
import MenuIcon from '@mui/icons-material/Menu';
import { blue } from "@mui/material/colors";
import ProductCategoryMenuItem from "./productCategoryMenuItem";
import HomeIcon from '@mui/icons-material/Home';
import FeedIcon from '@mui/icons-material/Feed';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PersonIcon from '@mui/icons-material/Person';

const optionsLanguage = ['Vietnam', 'English', 'Germany'];

function SelectLanguageComponent() {
    const { t, i18n } = useTranslation();

    const [openMenuLanguage, setOpenMenuLanguage] = React.useState(false);
    const anchorLanguageRef = React.useRef<HTMLDivElement>(null);
    const [selectedLanguage, setSelectedLanguage] = React.useState<number>(localStorage.getItem('selectedLanguage') ? Number(localStorage.getItem('selectedLanguage')) : 0);
    
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedLanguage(index);
        setOpenMenuLanguage(false);
    };

    // check use effect change langiage
    useEffect(() => {
        // check and set default language
        const language = localStorage.getItem('selectedLanguage');
        if (!language) {
            localStorage.setItem('selectedLanguage', '0');
        }
        // check change language
        if (selectedLanguage === 0) {
            i18n.changeLanguage('vi');
            localStorage.setItem('selectedLanguage', '0');
        } else if (selectedLanguage === 1) {
            i18n.changeLanguage('en');
            localStorage.setItem('selectedLanguage', '1');
        } else if (selectedLanguage === 2) {
            i18n.changeLanguage('de');
            localStorage.setItem('selectedLanguage', '2');
        }
    }, [selectedLanguage])

    const handleToggle = () => {
        setOpenMenuLanguage((prevOpen) => !prevOpen);
    };

    const handleCloseMenuLanguage = (event: Event) => {
        if (
        anchorLanguageRef.current &&
        anchorLanguageRef.current.contains(event.target as HTMLElement)
        ) {
        return;
        }
        setOpenMenuLanguage(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup
                variant="contained"
                ref={anchorLanguageRef}
                aria-label="split button"
                sx={{backgroundColor: 'white', alignSelf: 'center', mt: 1, mb: 1}}
                >
                <Button
                    size="small"
                    onClick={handleToggle}
                    startIcon={<Avatar
                        variant="square"
                        sx={{ width: 24, height: 24 }}
                        src={selectedLanguage === 1 ? flagAmerica : (selectedLanguage === 0 ? flagVietNam : flagGermany)}
                    />}
                >
                    {optionsLanguage[selectedLanguage]}
                </Button>
                <Button
                    size="small"
                    aria-controls={openMenuLanguage ? 'split-button-menu' : undefined}
                    aria-expanded={openMenuLanguage ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 2,
                    backgroundColor: 'white'
                }}
                open={openMenuLanguage}
                anchorEl={anchorLanguageRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                    transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                    <ClickAwayListener onClickAway={handleCloseMenuLanguage}>
                        <MenuList id="split-button-menu" autoFocusItem>
                            {optionsLanguage.map((option, index) => (
                                <MenuItem
                                key={option}
                                selected={index === selectedLanguage}
                                onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

function AccountMenu(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // set show account menu
    const [anchorShowAccountMenu, setAnchorShowAccountMenu] = React.useState<null | HTMLElement>(null);
    const showAccountMenu = Boolean(anchorShowAccountMenu);
    const handleClickShowAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorShowAccountMenu(event.currentTarget);
    };
    const handleCloseShowAccountMenu = () => {
        setAnchorShowAccountMenu(null);
    };

    return (<React.Fragment>
        {props.icon ? (
            <Avatar
                id="show-account-menu-button"
                aria-controls={showAccountMenu ? 'show-account-menu-popup' : undefined}
                aria-haspopup="true"
                aria-expanded={showAccountMenu ? 'true' : undefined}
                onClick={handleClickShowAccountMenu}
                sx={{ bgcolor: blue[900], ml: 1 }}
            >
                <PersonIcon />
            </Avatar>
        ) : (
            <Button
                variant="text"
                id="show-account-menu-button"
                aria-controls={showAccountMenu ? 'show-account-menu-popup' : undefined}
                aria-haspopup="true"
                aria-expanded={showAccountMenu ? 'true' : undefined}
                onClick={handleClickShowAccountMenu}
                sx={{color: 'white', textTransform: 'capitalize'}}
            >
                {t('account')}
            </Button>
        )}
        <Menu
            id="show-account-menu-popup"
            aria-labelledby="show-account-menu-button"
            anchorEl={anchorShowAccountMenu}
            open={showAccountMenu}
            onClose={handleCloseShowAccountMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={() => navigate(`/account`)}>{t('account')}</MenuItem>
            {isAuthenticated()
                ? (<MenuItem onClick={() => {
                    logout();
                    navigate(0);
                }}>{t('logout')}</MenuItem>)
                : (<MenuItem onClick={() => navigate(`/account`)}>{t('login')}</MenuItem>)
            }
        </Menu>
    </React.Fragment>
    )
}


const topMenus = ['introduce', 'contact', 'payment_instructions', 'news', 'account'];
const mainMenu = ['home', 'introduce', 'products', 'news', 'contact']

function ItemMenuMobile(props: any) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const toggleDrawer = (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
  
        props.setOnOpenMenu();
      };
  
    const menuMobileCategory = () => (
      <Box
        sx={{ width: 'auto' }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Divider />
        <List>
          {mainMenu.map((textPage, index) => (
            <ListItem key={textPage} disablePadding dense>
              <ListItemButton
                dense
                onClick={
                    () => {
                        if (textPage === 'products') return navigate('/category_product');
                        return (textPage === 'home') ? navigate(`/`) : navigate(`/${textPage}`);
                    }
                }
            >
                {(textPage === 'home') && <HomeIcon color="primary" />}
                {(textPage === 'contact') && <ContactPageIcon color="primary" />}
                {textPage === 'introduce' && <ArticleIcon color="primary" />}
                {textPage === 'products' && <InventoryIcon color="primary" />}
                {textPage === 'news' && <FeedIcon color="primary" />}
                <ListItemText sx={{ml: 2, color: primary, textTransform: 'capitalize'}} primary={textPage} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <ProductCategoryMenuItem productCategorys={props.productCategorys} isMobile={true}/>
        
        <Divider />
        <List>
            <ListItem disablePadding>
                {isAuthenticated() ? (
                    <ListItemButton dense onClick={() => {logout(); navigate(0);}}>
                        <LogoutIcon color="primary" />
                        <ListItemText sx={{ml: 2, color: primary}} primary={t('logout')} />
                    </ListItemButton>
                ) : (
                    <ListItemButton dense onClick={() => navigate(`/account`)}>
                        <LoginIcon color="primary" />
                        <ListItemText sx={{ml: 2, color: primary}} primary={t('login')} />
                    </ListItemButton>
                )}
            </ListItem>
        </List>
      </Box>
    );
  
    return (
        <React.Fragment>
            <Avatar
                variant="rounded"
                sx={{ bgcolor: blue[900]}}
                onClick={() => {
                    props.setOnOpenMenu();
                }}
            >
                <MenuIcon sx={{color: 'white'}} />
            </Avatar>
            <SwipeableDrawer
                anchor={'left'}
                open={props.onOpenMenu}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >   
                <SelectLanguageComponent />
                {menuMobileCategory()}
            </SwipeableDrawer>
        </React.Fragment>
    );
}

function Header(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [onOpenMenu, setOnOpenMenu] = React.useState<boolean>(false);
    const [isHomePage, setIsHomePage] = React.useState<boolean>(false);
    const location = useLocation();
    useEffect(() => {
        setIsHomePage(location.pathname === '/');
    }, [location])

    const slug = (str: any) => {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
      
        // remove accents, swap ñ for n, etc
        var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        var to   = "aaaaaeeeeeiiiiooooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
      
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                 .replace(/\s+/g, '-') // collapse whitespace and replace by -
                 .replace(/-+/g, '-'); // collapse dashes
      
        return str;
      };

    const seachProducts = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const searchInput = data.get('search_input')
        navigate(`/category_product?product_name=${slug(searchInput)}`)
    }

    return (
        <AppBar position="static" sx={{backgroundColor: primary}}>
            <Container maxWidth="xl" disableGutters>
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Box sx={{ width: '100%' }}>
                        <Box display="flex" sx={{float: { xs: 'none', md: 'left' }, mt: 1, mb: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Box display="flex">
                                <MailOutlineIcon sx={{ display: { xs: 'none', md: 'flex' } }} />
                                <Typography variant="body2" sx={{alignSelf: 'center', fontSize: {xs: '1em', sm: '1em'} }}>
                                    {props.company ? props.company[`email_${i18n.language}`] : 'Funico2009@yahoo.com'}
                                </Typography>
                            </Box>
                            <Box display="flex">
                                <CallIcon sx={{ ml: 1, display: { xs: 'none', md: 'flex' }, mr: 1, fontSize: {xs: '1em', sm: '1em'} }} />
                                <Hidden mdUp>
                                    <Typography variant="body2">&nbsp;-&nbsp;</Typography>
                                </Hidden>
                                <Typography variant="body2" noWrap sx={{alignSelf: 'center'}}>
                                    {props.company ? props.company[`phone_${i18n.language}`] : '02103.865.706'}
                                </Typography>
                            </Box>
                        </Box>
                        <Hidden mdDown>
                            <Box sx={{display: "flex", justifyContent: 'end'}}>
                                <nav>
                                    {topMenus.map((page) => {
                                        if (page == "account") {
                                            return (<AccountMenu icon={false} />)
                                        }
                                        return (<Typography
                                            variant="body2"
                                            noWrap
                                            component="a"
                                            sx={{
                                            mr: 2,
                                            color: 'inherit',
                                            textDecoration: 'none',
                                            }}
                                            onClick={() => navigate(`/${page}`)}
                                        >
                                            {t(page)}
                                        </Typography>)
                                    })}
                                </nav>
                                <SelectLanguageComponent />
                            </Box>
                        </Hidden>
                    </Box>
                    <Grid container alignItems="center">
                        <Hidden mdUp>
                            <Grid item xs={3}>
                                <ItemMenuMobile
                                    onOpenMenu={onOpenMenu}
                                    productCategorys={props.productCategorys}
                                    setOnOpenMenu={() => setOnOpenMenu(!onOpenMenu)}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item md={3} xs={6} pr={{ xs: 0, sm: 10, md: 10 }}>
                            <Box sx={{height: {xs: '85%', sm: '100%'}, width: {xs: '85%', sm: '100%'} }}>
                                <img style={{height: '100%', width: '100%', cursor: 'pointer'}} src={FunicoLogo} onClick={() => navigate('/')}/>
                            </Box>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item md={5}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                                onSubmit={seachProducts}
                                >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Tim kiem san pham"
                                    inputProps={{ 'aria-label': 'Tim kiem' }}
                                    name="search_input"
                                    id="search_input"
                                />
                                <IconButton sx={{ p: '10px' }} aria-label="search" type="submit">
                                    <SearchIcon />
                                </IconButton>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            </Paper>
                            </Grid>
                        </Hidden>
                        <Grid item md={4} xs={3}>
                            <Box sx={{float: 'right' }} display={'flex'}>
                                <CartHeader />
                                <AccountMenu icon={true} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                        <Grid container alignItems="baseline">
                            <Grid item xs={3}>
                                <Button
                                startIcon={<MenuIcon />}
                                variant="text"
                                sx={{color: 'white', display: isHomePage ? '' : 'none'}}
                                >
                                    {t('product_catalogue')}
                                </Button>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{ display: 'flex'}}>
                                    {mainMenu.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={() => {
                                                if (page === 'products') return navigate('/category_product');
                                                return (page === 'home') ? navigate(`/`) : navigate(`/${page}`);
                                            }}
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                        >
                                            {t(page)}
                                        </Button>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Toolbar>
            </Container>
        </AppBar >
    );
}
export default withTranslation()(Header);
import { 
    Grid,
    Typography,
    CardMedia,
    Divider,
    Box,
    Tabs,
    Tab,
    Button,
    Hidden,
    CircularProgress,
    Alert
} from "@mui/material"
import LayoutPage from '../../components/layoutPage';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { addProductToCart, formatPrice, urlImage } from "../../utils/common";
import SidebarsProduct from "../../components/sidebarsProduct";
import ProductSlideshow from "../home/ProductSlideshow";
import BreadcrumbsPath from "../../components/Breadcrumbs";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

// interface Product {
//     category_id: number
//     code: String
//     created_at: String
//     description_de: String
//     description_en: String
//     description_vi: String
//     id: number
//     img: String | any
//     name_de: String
//     name_en: String
//     name_vi: String
//     number_of_products: number
//     price: number
//     summary_de: String
//     summary_en: String
//     summary_vi: String
//     updated_at: String
//     [key: string]: any
// }

function ProductDetail() {
    const params = useParams();
    const { t, i18n } = useTranslation();
    const [product, setProduct] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if ( params?.productId  ) {
            setLoading(true);
            window.scrollTo(0, 0);
            setTimeout(() => {
                const getData = (doamin: any) => {
                    axios.get(
                        `${doamin}/products/${params?.productId}`,
                        {headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                          }})
                    .then(res => {
                        setProduct(res.data);
                        document.title = res.data["name_vi"];
                    })
                    .catch((error) => {
                        console.log('error', error)
                    }).finally(() => setLoading(false))
                }

                getData(process.env.REACT_APP_API_DOMAIN)
                
            }, 500);
        }
    }, [params?.productId])

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleClick = () => {
        window.location.href = "tel:+84869686889";
      };

    return (
        <LayoutPage>
            <Grid container spacing={2} mb={4}>
                <Grid item xs={12}>
                    <BreadcrumbsPath pageName={product && product[`name_${i18n.language}`]} />
                </Grid>
                <Hidden smDown>
                    <Grid item md={3} sm={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
                {loading && (
                    <Grid justifyContent="center" item md={9} sm={9} xs={12} sx={{clear: 'both'}}>
                        <CircularProgress color="success" />
                    </Grid>
                )}
                {!loading && (
                    <Grid item md={9} sm={9} xs={12} container spacing={1}>
                        {!product?.id && (
                            <Grid item xs={12}>
                                <Alert severity="info">URL không hợp lệ, vui lòng liên hệ với quản trị viên trang web!</Alert>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <img src={product && urlImage(product.img)} alt={product && product["name_vi"]}  style={{ width: '100%' }}/>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'left'}}>
                            <Typography variant="h6" color={'primary'}>{product && product[`name_${i18n.language}`]}</Typography>
                            {/* <Typography variant="h6" color="red">{product && formatPrice(product?.price || '0')}</Typography> */}
                            {/* <Button startIcon={<AddShoppingCartIcon />} onClick={() => {addProductToCart(product);document.getElementById('cart-icon')?.click();}} variant="contained">{t('add_to_cart')}</Button> */}
                            <Button variant="contained" onClick={handleClick} style={{backgroundColor: "#fc7600"}}>Gọi đặt hàng - 0869.68.68.89</Button>
                            <Hidden mdDown>
                                <Divider sx={{mt: 2}}/>
                                <Typography
                                variant="body2"
                                sx={{'line-height': 'normal'}}
                                dangerouslySetInnerHTML={{
                                    __html: product && product[`summary_${i18n.language}`]
                                }}
                                />
                            </Hidden>
                        </Grid>
                        <Hidden mdUp>
                            <Grid item xs={12}>
                                <Divider sx={{mt: 2}} />
                                <Typography
                                variant="body2"
                                sx={{textAlign: 'left', 'line-height': 'normal'}}
                                dangerouslySetInnerHTML={{
                                    __html: product && product[`summary_${i18n.language}`]
                                }}
                            />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <Tabs sx={{left: '50%'}} value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={t('describe')} {...a11yProps(0)} />
                                </Tabs>
                                <Divider />
                                <TabPanel value={value} index={0}>
                                    <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: product && product[`description_${i18n.language}`] }}></div>
                                </TabPanel>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {product && product?.id && <ProductSlideshow productCat={{exclude_id: product["id"], category_id: product["category_id"]}}/>}
                        </Grid>
                    </Grid>
                )}
                <Hidden smUp>
                    <Grid item md={3} sm={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
            </Grid>
        </LayoutPage>
    );
}

export default ProductDetail;